<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="atom-richtext"
    :class="{ 'is-on-grid': isOnGrid }"
  >
    <div
      class="atom-richtext__inner"
      v-html="compiledHtml"
    />
  </div>
</template>

<script setup>

const props = defineProps({
    html: {
        type: [String, Object],
        required: true,
        default: null,
    },

    textColor: {
        type: String,
        default: 'var(--c-black)',
    },

    alignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'right'].includes(value),
    },

    isOnGrid: {
        type: Boolean,
        default: false,
    },
});

const { textColor, alignment } = toRefs(props);
const { richTextResolver } = useStoryblokApi();

const compiledHtml = computed(() => {
    if (isObject(props.html)) {
        return richTextResolver.render(props.html);
    }

    return props.html;
});
</script>

<style lang="scss" scoped>
.atom-richtext{
    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();

        margin-bottom: var(--m-bottom--small);
    }
}

.atom-richtext__inner {
    color: v-bind(textColor);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-weight--400);
    line-height: var(--l-height--p);
    text-align: v-bind(alignment);

    .is-on-grid & {
        @include default-content-columns;
    }

    ::v-deep(ul)  {
        @include fluid-simple('margin-bottom', 16px, 19px);
        display: inline-block;
        padding-left: 25px;

        li {
            position: relative;

            &:before {
                position: absolute;
                top: 14px;
                left: -15px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: v-bind(textColor);
                content: '';
            }
        }
    }

    ::v-deep(strong) {
        font-weight: var(--f-weight--900);
    }

    ::v-deep(b) {
        font-weight: var(--f-weight--900);
    }

    ::v-deep(p)  {
        margin: var(--f-size--p) 0;

        b, strong {
            font-family: var(--f-family--primary);
        }

        &:last-child {
            margin: 0;
        }
    }

    ::v-deep(a) {
        color: var(--c-primary);
        text-decoration: underline;
    }
}
</style>
