<template>
  <main class="main-content">
    <NuxtLazyHydrate
      v-for="(item, lcpIndex) of data"
      :key="item._uid"
      v-bind="getHydrationProp(item.component)"
      @hydrated="onHydrated(item.component)"
    >
      <component
        :is="useComponent(item.component)"
        v-bind="buildProps(item.component, item, lcpIndex)"
      />
    </NuxtLazyHydrate>
  </main>
</template>

<script setup>
defineProps({
    data: {
        type: Array,
        default: () => [],
    },
});

/*
  Here you can add additional mappings for your storyblok
  components to match the cms agnostic components
*/
const bypassLazyHydration = inject('isStoryblokPreview', false);

const buildProps = (component, data, lcpIndex) => {
    const originalProps = { ...data };

    /*
      AtomImage
    */
    if (component === 'AtomImage') {
        originalProps.provider = 'storyblok';
        originalProps.width = 1500;
        originalProps.lazy = lcpIndex < 3 ? false : originalProps.lazy;
        originalProps.preload = lcpIndex < 3;
        originalProps.sizes = 'xs:320px sm:480px md:750px lg:1200px xl:1500px';
    }

    /* Set the on grid prop to true */
    originalProps.isOnGrid = true;

    /* Remove storyblok system props for cleaner markup */
    delete originalProps.component;
    delete originalProps._uid;

    return originalProps;
};

/*
  Add more components here if you need them in the storybook render loop
  Remember to also add the component name to the hydrate functions below
*/
const availableComponents = {
    AtomTextHeading: resolveComponent('AtomTextHeading'),
    AtomTextRichtext: resolveComponent('AtomTextRichtext'),
    AtomImage: resolveComponent('AtomImage'),
};

const useComponent = (key) => availableComponents[key] || null;

/*
  Hydration management
*/
const hydrateInstant = [
];

const hydrateOnInteraction = [
];

const hydrateOnVisible = [
];

const hydrateNever = [
    'AtomTextHeading',
    'AtomImage',
    'AtomTextRichtext',
];

// Careful with this one, on safari there is no idle callback so it takes 6 seconds to hydrate
const hydrateOnIdle = [];

const getHydrationProp = (componentName) => {
    if (bypassLazyHydration || hydrateInstant.includes(componentName)) {
        return { whenTriggered: true };
    }

    if (hydrateOnIdle.includes(componentName)) {
        return { whenIdle: true };
    }

    if (hydrateOnInteraction.includes(componentName)) {
        return {
            onInteraction: [
                'touchstart',
                'focus',
                'mouseenter',
            ],
        };
    }

    if (hydrateOnVisible.includes(componentName)) {
        return { whenVisible: { rootMargin: '50px' } };
    }

    if (hydrateNever.includes(componentName)) {
        return { never: true };
    }

    return {};
};

const debug = false;
const onHydrated = (componentName) => {
    if (!debug) return;
    // eslint-disable-next-line no-console
    console.log(`Component ${componentName} hydrated`);
};
</script>
