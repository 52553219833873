<template>
  <UtilRendererStoryblok
    v-if="mainContent"
    :data="mainContent"
  />
</template>

<script setup>
const mainContent = ref([]);
</script>
